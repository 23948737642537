<template>
	<div>
		<section class="header-bg" style="">
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">ABOUT</strong>
				</p>
			</div>
		</section>
		<section class="container-1200" style="margin: 40px auto;">
			<div style="text-align: justify;line-height: 30px;font-size: 16px;padding: 10px;margin-top: 100px;"
				class="layui-collapse">
				<div style="text-align: justify;margin-top: 10px;padding:0px 30px;line-height: 28px;">
					<h2><strong>ABOUT US</strong></h2>
					<p style="margin-top: 20px;">
						Contrimetric is shortened from Contribution Metric,which is called Article Citation Contribution
						Indicator(ACCI), or Contribution Factor(CF). Each academic paper has two ACCI values:
						ACCI-I(CF1)
						and ACCI-II(CF2), which is a new designed indicator for scientific contribution. When it is
						positive, it shows that
						this paper had brought positive contribution comparing to average citations each paper receiving
						in
						terms of citation contribution in its journal impact factor. The ACCI/CF formula is defined as
						ACCI=(C-JIF)/JIF, where C is
						citations number, JIF is Journal Impact Factor in certain year.
					</p>
				</div>
			</div>
		</section>

	</div>

</template>
<script>
	export default {
		name: "verify",
		data() {
			return {
			};
		},
		watch: {},
		created() {
		
		},
		mounted() {},
		methods: {
			
		},
	}
</script>

<style>
	
</style>
